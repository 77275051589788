import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppComponent} from './app.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'information',
        loadChildren: () => import('./modules/information/information.module').then(m => m.InformationModule),
      },
      {
        path: 'for-candidate',
        loadChildren: () => import('./modules/for-candidate/for-candidate.module').then(m => m.ForCandidateModule),
      },
      {
        path: 'for-employer',
        loadChildren: () => import('./modules/for-employer/for-employer.module').then(m => m.ForEmployerModule),
      },
      {
        path: 'about-us',
        loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
